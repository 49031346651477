<template lang="pug">
v-card(v-if="Object.keys(statement).length").pa-4
  v-card-title.d-flex.justify-space-between.align-center
    div(v-if="statement.behavior") {{ $t(`${Object.keys(statement.behavior)[0]}-${type}`, { number: statement.number }).toUpperCase() }}
      span(v-if="checkAccess('backOffice')").ml-1 (ID: {{ statement.id }})
    ControlButtons(:controlButtons="controlButtons")
  v-card-text
    v-divider
    v-progress-linear(v-if="isLoading" indeterminate)
    div(v-if="statement.behavior")
      Info(v-if="statement.behavior.viewInfoBlock" :statement="statement" :comments="comments")
      FormEdit(v-else-if="statement.behavior.viewEditBlock" :statement="statement" actionType="edit")
      EditStatus(v-else-if="statement.behavior.viewEditStatusBlock" :statement="statement")
      ViewPhotoList(
        v-else-if="statement.behavior?.viewFilesBlock"
        :files="statement.files"
        :sailorDocument="statement"
        :documentType="type"
        :deleteFile="removeFileStatementSQC"
        :editFile="updateFileStatementSQC"
        isShowEdit
      )
v-progress-linear(v-else indeterminate)
</template>

<script>

import { viewDetailedComponent, back, deleteConfirmation, copyToClipboard } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { mapState, mapGetters, mapActions } from 'vuex'
import { SUCCESS_CODE } from '../../../configs/constants'

export default {
  components: {
    Info: () => import('@/views/Sailor/SailorSQCStatement/Info.vue'),
    FormEdit: () => import('@/views/Sailor/SailorSQCStatement/Form.vue'),
    EditStatus: () => import('@/views/Sailor/SailorSQCStatement/EditStatus.vue'),
    ViewPhotoList: () => import('@/components/atoms/ViewFile/ViewFile.vue'),
    ControlButtons: () => import('@/components/atoms/ControlButtons')
  },
  data () {
    return {
      type: 'sailorSQCStatement',
      comments: [],
      viewDetailedComponent,
      deleteConfirmation,
      checkAccess,
      back,
      controlButtons: [
        {
          id: 8,
          name: 'mdi-content-copy',
          action: () => copyToClipboard(location.href),
          color: '#42627e',
          tooltip: 'documentIconControl.copyLink',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 1,
          name: 'mdi-information-outline',
          action: () => viewDetailedComponent(this.statement, 'viewInfoBlock'),
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.info',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 2,
          name: 'mdi-pen',
          action: () => viewDetailedComponent(this.statement, 'viewEditBlock'),
          checkAccess: () => checkAccess('sailorSQCStatement', 'edit', this.statement),
          color: '#42627e',
          tooltip: 'documentIconControl.edit',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 6,
          name: 'mdi-sync',
          action: () => viewDetailedComponent(this.statement, 'viewEditStatusBlock'),
          checkAccess: () => checkAccess('sailorSQCStatement', 'editStatus', this.statement),
          color: '#42627e',
          tooltip: 'documentIconControl.editStatus',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 3,
          name: 'mdi-image-outline',
          action: () => viewDetailedComponent(this.statement, 'viewFilesBlock'),
          checkAccess: () => checkAccess('sailorSQCStatement', 'view_file'),
          color: '#42627e',
          tooltip: 'documentIconControl.files',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 4,
          name: 'mdi-trash-can-outline',
          action: () => this.deleteDocument(),
          checkAccess: () => checkAccess('sailorSQCStatement', 'delete', this.statement),
          color: '#F73E07',
          tooltip: 'documentIconControl.delete',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 5,
          name: 'mdi-close',
          action: () => back('sqc-statements'),
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.close',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['statementByID']),
    ...mapState({
      id: state => state.sailor.sailorId,
      isLoading: state => state.sailor.isLoading,
      statement: state => state.sailor.statementSQCById
    })
  },

  mounted () {
    this.getData()
  },
  methods: {
    ...mapActions(['getSQCStatementByID', 'getSQCStatementComments', 'removeSQCStatements',
      'removeFileStatementSQC', 'updateFileStatementSQC']),

    async getData () {
      await this.getSQCStatementByID({ ...this.$route.params })
    },
    //
    //     getDocumentComments () {
    //       this.getSQCStatementComments(this.documentID).then(response => {
    //         if (response?.code === 200) this.comments = response.data
    //       })
    //     },

    deleteDocument () {
      deleteConfirmation().then(async confirmation => {
        if (confirmation) {
          const response = await this.removeSQCStatements({ ...this.$route.params })
          if (SUCCESS_CODE.includes(response?.code)) {
            this.$router.push({ name: 'sqc-statements' })
            this.$notification.success('deletedDocument')
          }
        }
      })
    }
  }
}
</script>
